import React, { useState } from "react";
import Tweet from "./tweet";

function App() {
  const [isRed, setRed] = useState(false); /*fgfdgdfg */
  const [count, setCount] = useState(0);
  const [users, setUser] = useState([
    { name: "Wendy", message: "I love cheese" },
    { name: "Toby", message: "I love soldering" },
    { name: "Barry", message: "I love programming" },
    { name: "Levi", message: "I love Mommy" },
  ]);

  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    setCount(count > 0 ? count - 1 : 0);
  };

  return (
    <div className="App">
      {users.map((user) => (
        <Tweet name={user.name} message={user.message} />
      ))}

      <button onClick={increment}>Moo {count} </button>
      <button onClick={decrement}>ooM {count} </button>
    </div>
  );
}

export default App;
